<style lang="scss">
.martkplatz-bildung-beruf {
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .custom-link {
    color: $blue-dark !important;
    font-weight: 500;
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
    h2 {
    }
    .bild-box {
      img {
        display: block;
        margin: 0 auto;
      }
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }

  .tab-group-container {
    .title-container {
      @media (max-width: 1350px) {
        flex-wrap: wrap;
        align-items: stretch;
      }
      .title {
        @media (max-width: 1350px) {
          flex: 1 0 25%;
          display: inline-block;
        }
        @media (max-width: 1200px) {
          flex: 0 33.33%;
          display: inline-block;
        }
        @media (max-width: 992px) {
          flex: 0 100%;
          display: block;
        }
      }
    }
  }
  .download-pdf {
    a {
      max-width: 100%;
    }
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    max-width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="martkplatz-bildung-beruf content container">
    <h1>Beruf &amp; Bildung</h1>
    <p class="intro-txt">Platzieren Sie Ihre Anzeigen im größten Job-Markt Österreichs und finden Sie mit der Kleinen Zeitung Ihren Wunschmitarbeiter - Jeden Samstag (Hauptmarkt) und Mittwoch (Zweitmarkt) in der Zeitung.</p>
    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/beruf-bildung.jpg" alt="Beruf Bildung" class="img-fluid" />
    </div>

    <h2>Individuelle Werbelösungen</h2>
    <p>Nutzen Sie unser umfangreiches Portfolio, um Ihr Werbeziel optimal zu erreichen.</p>

    <TabGroup>
      <Tab tabid="berufbildung-tab1" :title="'Raum- und Zeilenanzeigen'" active>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h3>Produktdaten</h3>
            <p>Offene Stellen sollen idealerweise schnell und langfristig besetzt werden. Wir helfen Ihnen, die richtigen Mitarbeiter zu finden.</p>
            <p><strong>Anzeigenschluss</strong><br />Donnerstag, 15 Uhr für Samstag<br />Dienstag, 10 Uhr für Mittwoch</p>
            <p><strong>Wiederholungsschaltung</strong><br />50 % auf den Normaltarif bei Schaltung des gleichen Sujets wie bei Erstschaltung innerhalb von 14 Tagen</p>
            <p>Alle Raumanzeigen ab der Größe einer 1/8 Seite werden zusätzlich für 4 Wochen als <router-link :to="{ name: 'JobPortfolio' }" class="custom-link">Job-Ticker</router-link> ausgespielt.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//marktplaetze/prod-beruf-bildung.png" alt="Beruf und Bildung" class="img-fluid" />
          </div>
        </div>
        <!-- TABELLEN -->
        <div class="tabelle-box tabelle-width-50">
          <div class="headline">
            <strong>Millimeterpreis 4C</strong>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-right">Kombination</div>
            <div class="col-lg-4 text-align-right">Steiermark</div>
            <div class="col-lg-4 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-4 col-6 text-align-right">21,88</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-4 col-6 text-align-right">15,48</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-4 col-6 text-align-right">9,47</div>
          </div>
        </div>
        <h3>Raumanzeigen</h3>
        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/8 Seite </strong>
                Format: 47 x 135
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-right">Kombination</div>
                <div class="col-lg-4 text-align-right">Steiermark</div>
                <div class="col-lg-4 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-4 col-6 text-align-right">2.953,80</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-4 col-6 text-align-right">2.089,80</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-4 col-6 text-align-right">1.278,45</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/4 Seite </strong>
                Format: 98 x 135 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-right">Kombination</div>
                <div class="col-lg-4 text-align-right">Steiermark</div>
                <div class="col-lg-4 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-4 col-6 text-align-right">5.907,60</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-4 col-6 text-align-right">4.179,60</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-4 col-6 text-align-right">2.556,90</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/2 Seite </strong>
                Format: 200 x 135 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-right">Kombination</div>
                <div class="col-lg-4 text-align-right">Steiermark</div>
                <div class="col-lg-4 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-4 col-6 text-align-right">11.815,20</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-4 col-6 text-align-right">8.359,20</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-4 col-6 text-align-right">5.113,80</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/2 Seite </strong>
                Format: 98 x 260 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-right">Kombination</div>
                <div class="col-lg-4 text-align-right">Steiermark</div>
                <div class="col-lg-4 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-4 col-6 text-align-right">11.377,60</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-4 col-6 text-align-right">8.049,60</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-4 col-6 text-align-right">4.924,40</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x260.svg" alt="98x260" class="img-fluid " />
          </div>
        </div>
        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>1/1 Seite </strong>
                Format: 200 x 275 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-4 text-align-right">Kombination</div>
                <div class="col-lg-4 text-align-right">Steiermark</div>
                <div class="col-lg-4 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-4 col-6 text-align-right">24.068,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-4 col-6 text-align-right">17.028,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-4 col-6 text-align-right">10.417,00</div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
          </div>
        </div>
        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>Top-Job der Woche</strong>
                Format: 90 x 40 mm (inkl. Rahmen: 98 x 70 mm)
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">SA</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">1.390,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">990,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">590,00</div>
              </div>
              <div class="info-txt-tabelle">Erscheinung nur samstags möglich. <br />Reservierungspflichtig: nur nach Verfügbarkeit buchbar.</div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-90x40.svg" alt="90x40" class="img-fluid " />
          </div>
        </div>

        <div class="row format-box">
          <div class="col-lg-7 order-lg-2">
            <div class="tabelle-box">
              <div class="headline">
                <strong>Rubrikenvorspann</strong>
                Format: 200 x 110 mm
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-center">MI</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">1.990,00</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">1.500,00</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">900,00</div>
              </div>
              <div class="info-txt-tabelle">Erscheinung nur mittwochs möglich.<br />Pro Mittwochausgabe nur ein Rubrikenvorspann verfügbar.</div>
            </div>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x110.svg" alt="200x110" class="img-fluid " />
          </div>
        </div>

        <h3>Zeilenanzeigen</h3>
        <p>Ihre Möglichkeit, eine Zeilenanzeige im seriösen und reichweitenstarken Stellenmarkt im Süden Österreichs zu platzieren.</p>
        <div class="row">
          <div class="col-lg-8 justify-content-center align-self-center">
            <div class="tabelle-box alignItems">
              <div class="headline">
                <strong>Zeilenanzeigen </strong>
              </div>
              <div class="row head-row hide-small">
                <div class="col-lg-3"></div>
                <div class="col-lg-3 text-align-right">Kombination</div>
                <div class="col-lg-3 text-align-right">Steiermark</div>
                <div class="col-lg-3 text-align-right">Kärnten</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Premium-Anzeigen</span> (inkludiert 15 Zeilen Text, zweizeiliges Logo)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">428,26</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">335,33</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">287,02</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Basis-Anzeigen</span> (inkludiert 5 Zeilen Text)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">84,80</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">54,81</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">40,74</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Jede weitere Zeile</span></div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">16,05</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">10,45</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">7,72</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Logo 4c</span> je Zeile</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">16,48</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">13,01</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">9,54</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-3 text-align-left"><span class="strong">Wiederholung</span> (Zweitmarkt-Schaltmöglichkeit am Mittwoch)</div>
                <div class="col-6 text-align-left visible-small">Kombination</div>
                <div class="col-lg-3 col-6 text-align-right">-50%</div>
                <div class="col-6 text-align-left visible-small">Steiermark</div>
                <div class="col-lg-3 col-6 text-align-right">-50%</div>
                <div class="col-6 text-align-left visible-small">Kärnten</div>
                <div class="col-lg-3 col-6 text-align-right">-50%</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//marktplaetze/bsp-beruf-bildung.png" alt="Bsp. Beruf und Bildung" class="img-fluid" />
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="bildungberuf-tab2" :title="'Sponsored-Karriere Story '">
        <div class="row">
          <div class="col-lg-12">
            <h3>Sponsored Karriere-Story</h3>
            <p>Ihre Native Werbung im Karriereleiter Newsletter.</p>
            <p>Mit der Sponsored Story kreieren unsere Native Experten gemeinsam mit Ihnen lesenswerte, digitale Inhalte speziell für Ihre Zielgruppe. Ihre Botschaften überzeugen im redaktionellen Look &amp; Feel. Sie bleiben in positiver Erinnerung.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/marktplaetze/sponsored-karriere-story.png" alt="Sponsored Karriere Story" class="img-fluid img-center" />
            </div>
            <br />
            <div class="bild-box">
              <img src="@/assets/img/img-content/marktplaetze/sponsored-karriere-story-v2.png" alt="Sponsored Karriere Story" class="img-fluid img-center" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <strong>PAKETINHALTE</strong>
            <ul class="content-ul">
              <li>Ausführliches Briefing, Projektmanagement, Content-Produktion, Set-Up-Kosten, Bewerbung, umfassendes Reporting</li>
            </ul>
            <br />
            <strong>LEISTUNG</strong>
            <ul class="content-ul">
              <li>5.000 garantierte Leser</li>
              <li><strong>Native-Ad-Teaser:</strong> Das Layout des Teasers im Newsletter integriert sich optisch in die übrigen Artikel und wird weniger als Werbemittel, sondern als Meldung im regulären Lesefluss wahrgenommen.</li>
              <li>Platzierung im Kleine Zeitung Karriere-Umfeld (kleinezeitung.at/karriere)</li>
              <li>Bewerbung der Sponsored Story auf allen Plattformen von kleinezeitung.at und in den sozialen Netzwerken</li>
            </ul>
            <div class="preis-box">
              <span><strong>Tarif:</strong> 5.135,00</span>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="bildungberuf-tab3" :title="'Unternehmensvorstellung #klzpräsentiert'">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-12">
              <h2>Ihre Unternehmensvorstellung<br />#klzpräsentiert</h2>
            </div>
            <div class="col-lg-5 order-lg-2">
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/garantierte-leser-bubble.jpg" alt="1500 garantierte Leser" class="img-fluid" />
                <br />
                <img src="@/assets/img/img-content/marktplaetze/unternehmensvorstellung-v2.jpg" alt="Unternehmensvorstellung" class="img-fluid" />
              </div>
            </div>
            <div class="col-lg-7">
              <strong>PAKETINHALTE</strong>
              <ul class="content-ul">
                <li>Briefing (grobe, inhaltliche Ausrichtung - Zitat und Foto), Projektmanagement, Content-Produktion, Set-Up-Kosten, Bewerbung, Reporting</li>
              </ul>
              <br />
              <strong>LEISTUNG</strong>
              <ul class="content-ul">
                <li>Unternehmensvorstellung (1.500 bis 2.000 Zeichen, Person im Fokus)</li>
                <li>Info-Kasten beim Artikel mit Name, Kontaktdaten, Verlinkung zu aktuellen Jobangeboten (optional)</li>
                <li>Platzierung der Unternehmensvorstellung im Kleine Zeitung Karriere-Umfeld</li>
                <li>
                  Bewerbung der Unternehmensvorstellung auf allen Plattformen der Kleinen Zeitung
                  <ul class="content-ul">
                    <li>inklusive den sozialen Netzwerken (gesponsorte Kampagne über Facebook und Instagram, organische Instagram Story Slide (im vorgefertigten Design) mit Swipe Up zum Artikel und Verlinkung des Unternehmens)</li>
                    <li>und einem Native-Ad-Teaser im Karriereleiter Newsletter.</li>
                  </ul>
                </li>
              </ul>
              <div class="preis-box">
                <span><strong>Tarif: 2.279,00 </strong></span>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="bildungberuf-tab4" :title="'Job-Ticker'">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-6">
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/job-ticker-device.png" alt="Job Ticker" class="img-fluid" />
              </div>
            </div>
            <div class="col-lg-6">
              <h2>Job-Ticker</h2>
              <p>Wussten Sie, dass sich 60 % der Österreicher für Job-Anzeigen interessieren, ohne aktiv danach auf Job-Portalen suchen? Nutzen Sie dieses Potenzial mit dem neuen Job-Ticker der Kleinen Zeitung und machen Sie auf Ihr Angebot aufmerksam.</p>
            </div>
          </div>
        </div>
        <br /><br />
        <div class="row">
          <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/marktplaetze/d-job-pakete.jpg" alt="Job Pakete" class="img-fluid shadow" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <p>Ihre digitale Job-Zeilenanzeige als „News“-Ticker mit aktuellen Stellenanzeigen aus der Region. Erreichen Sie mit dem Job-Ticker die latent Job-Interessierten, die für Ihr Stellenagebot in Frage kommen, aber nicht aktiv auf einem Job-Portal nach Ihrer Anzeige suchen.</p>
            <p><strong>Platzierung</strong><br />Wirtschaft, Karriere, Regionalseiten</p>
          </div>
        </div>

        <div class="row align-self-center">
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Leistung &amp; Tarif</h3>
            <ul class="content-ul">
              <li>In einen Job-Ticker rotieren bis zu 20 Jobanzeigen</li>
              <li>Abbildung inkl. Firmenname und Logo, Job-Bezeichnung, Standort, Call-to-Action Button + Verlinkung Unternehmenswebsite</li>
              <li>Laufzeit: 1 Monat</li>
            </ul>
            <div class="preis-box">
              <span>Tarif: <strong>324,00</strong></span>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro und 20 % Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="bildungberuf-tab5" :title="'Programmatic Job Ads'">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/marktplaetze-job-ads-v2.jpg" alt="Job Pakete" class="img-fluid shadow" />
              </div>
            </div>
            <div class="col-lg-6 justify-content-center align-self-center">
              <h2>Programmatic Job Ads</h2>
              <p>Branchenspezifisches Content-Targeting über das gesamte programmatische Netzwerk rundet Ihre Raum-bzw. Zeilenanzeige ab. Erreichen Sie auch jene, die nicht aktiv suchen. Das mit minimalen Aufwand für Sie.</p>
            </div>
          </div>
        </div>
        <h3>Nutzen Sie die Möglichkeit auf Ihre Stellenangebote auch programmatisch aufmerksam zu machen</h3>
        <div class="row wussten-sie-box">
          <div class="col-lg-12"><strong>Wussten Sie, dass ...</strong></div>
          <div class="col-lg-6">
            <div class="row margin-30-0">
              <div class="col-lg-4 justify-content-center align-self-center">
                <img src="@/assets/img/img-content/digital/job-lampe.png" alt="Job Lampe" class="img-fluid " />
              </div>
              <div class="col-lg-8 justify-content-center align-self-center">
                <p>
                  <strong>60 % latent Job-Interessierte<sup>1</sup></strong> für Ihr Stellenagebot in Frage kommen, die nicht aktiv auf einem Job-Portal nach Ihrer Anzeige suchen?
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row margin-30-0">
              <div class="col-lg-4 justify-content-center align-self-center">
                <img src="@/assets/img/img-content/digital/job-auto.png" alt="Job Lampe" class="img-fluid " />
              </div>
              <div class="col-lg-8 justify-content-center align-self-center">
                <p>
                  <strong>77% der Berufstätigen<sup>2</sup></strong> für Ihren Traumjob maximal 50 km pendeln würden?
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <p>Mit unseren digitalen Job-Produkten erreichen Sie genau diese Zielgruppe und erzeugen zusätzliche Aufmerksamkeit für Ihr Stellenangebot.</p>
          </div>
        </div>
        <div class="row margin-50-0">
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content/digital/job-flexible.png" alt="Job Flexible" class="img-fluid img-center " />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Flexible</h3>
            <ul class="content-ul">
              <li>Werbemittel: <strong>im individuellen Design</strong></li>
              <li>Werbemittelerstellung <strong>kostenlos im Creative Generator</strong> oder <strong>Selbstanlieferung</strong></li>
              <li>Targeting: <strong>individuell wählbar</strong> (vorzugsweise regional)</li>
              <li><router-link :to="{ name: 'Programmatic Advertising' }" class="custom-link">Themen-Targeting zusätzlich wählbar</router-link></li>
              <li>Kampagnenerstellung: <strong>selbstständig</strong></li>
              <li>Kampagnenstart: <strong>frei wählbar</strong></li>
              <li>Laufzeit: <strong>flexibel</strong></li>
            </ul>
            <div class="preis-box">
              <span><strong>Tarif: 500,00</strong></span>
            </div>
          </div>
        </div>
        <div class="row margin-50-0">
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content/digital/job-premium.png" alt="Job Premium" class="img-fluid img-center " />
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <h3>Premium</h3>
            <ul class="content-ul">
              <li>Werbemittel <strong>individuell nach Ihren Wünschen erstellt</strong></li>
              <li>Werbemittelerstellung: <strong>durch Design- Profis der Kleinen Zeitung</strong></li>
              <li>Targeting: <strong>individuell wählbar</strong> (vorzugsweise regional)</li>
              <router-link :to="{ name: 'Programmatic Advertising' }" class="custom-link">Themen-Targeting zusätzlich wählbar</router-link>
              <li>Kampagnenerstellung: <strong>serviciert</strong></li>
              <li>Kampagnenstart: <strong>frei wählbar</strong></li>
              <li>Laufzeit: <strong>4 Wochen</strong></li>
            </ul>
            <div class="preis-box">
              <span><strong>Tarif: 699,00</strong></span>
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro. Fixpaket kann nicht rabattiert werden.<br />*Inkludierte Leistungen Premium: Programmatic 500,00 / Werbemittelerstellung: 295,00 / Servicierung der Kampagne: 55,00 / <strong>Tarifwert: 850,00</strong></div>
        <br /><br />
        <p class="text-center">Für weitere Informationen kontaktieren Sie bitte Ihren Kleine Zeitung Berater.</p>
        <div class="quelle-txt text-center d-block">
          <sup>1</sup> Internetstudie MindTake 360: We Are Developers-Studie 2019<br />
          <sup>2</sup> Internetstudie Jänner 2020 vom Marktforschungsinstitut Innofact AG für ImmobilienScout24
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro und 20 % Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="bildungberuf-tab7" :title="'Karriereleiter Newsletter'">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-6">
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/d-karriere-newsletter.jpg" alt="Karriereleiter-Newsletter" class="img-fluid shadow" />
              </div>
            </div>
            <div class="col-lg-6">
              <h2>Karriereleiter-Newsletter</h2>
              <p><strong>Wir bringen weiter, was dich weiterbringt.</strong><br />Der Karriereleiter-Newsletter versorgt unsere Leser jeden Dienstag mit den wichtigsten Karriere-Themen, stellt Top-Arbeitgeber in Steiermark und Kärnten vor und liefert die Top Jobs aus der Region.</p>
              <p>Präsentieren Sie sich, Ihre Job-Angebote und Ihre Arbeit gegenüber der richtigen Zielgruppe und erreichen Sie somit Ihre zukünftigen BewerberInnen.</p>
              <div class="preis-box">
                <span>Tarif ab: <strong>150,00 </strong></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <h3>Tob Jobs der Woche</h3>
            <p>Ihre digitale Stellenanzeige im Karriereleiter Newsletter.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <div class="bild-box">
              <img src="@/assets/img/img-content/marktplaetze/bsp-top-jobs.png" alt="Bsp. Tob Jobs" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <strong>LEISTUNG</strong>
            <ul class="content-ul">
              <li>Platzierung Ihres Stellenangebotes im Karriereleiter-Newsletter</li>
              <li>Abbildung möglich in der Kategorie Steiermark oder Kärnten</li>
              <li>Abbildung inkl. Logo, Job-Titel, Firmenname, Standort, „Mehr erfahren“-Link zur Abbildung des Stellenangebotes auf der Unternehmensseite</li>
            </ul>
            <div class="preis-box">
              <span><strong>Tarif:</strong> 150,00</span>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="bildungberuf-tab6" :title="'willhaben.at'">
        <div class="portfolio-box">
          <div class="row">
            <div class="col-lg-6 justify-content-center align-self-center">
              <div class="bild-box">
                <img src="@/assets/img/img-content/marktplaetze/jobs-klz-willhaben.jpg" alt="Kleine Zeitung + Willhaben" class="img-fluid" />
              </div>
            </div>
            <div class="col-lg-6 justify-content-center align-self-center">
              <h2>willhaben.at</h2>
              <p>Profitieren Sie von der optimalen Kombination „Kleine Zeitung“ und „willhaben“. Mit der reichweitenstärksten Tageszeitung im Süden Österreichs und dem größten digitalen Marktplatz des Landes nutzen Sie das erfolgreichste Kommunikationsnetzwerk Österreichs.</p>
            </div>
          </div>
        </div>
        <br /><br />
        <h3>Ihre Werbemöglichkeiten als Add-On zur Kleinen Zeitung.</h3>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="tabelle-box alignItems">
              <div class="headline"><strong>Stellenanzeige</strong> mit Ihrem Logo / Bild inkl. Vorreihung</div>
              <ul class="content-ul">
                <li>Anzeige selbst erstellen und verwalten</li>
                <li>Layoutvorlagen zur Auswahl</li>
                <li>Optimiert für alle Endgeräte</li>
                <li>60 Tage Laufzeit mit wöchentlicher Vorreihung</li>
              </ul>
              <div class="row head-row">
                <div class="col-lg-6 col-6 text-align-left">Jobanzeige</div>
                <div class="col-lg-6 col-6 text-align-right">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-6 text-align-left">1 Anzeige</div>
                <div class="col-lg-6 col-6 text-align-right">300,00</div>
              </div>
            </div>
            <!-- -->
            <div class="tabelle-box alignItems">
              <div class="headline"><strong>Print Plus Zeilenanzeige</strong> Self-Service</div>
              <ul class="content-ul">
                <li>Ihre Zeilenanzeige wird zusätzlich 60 Tage auf willhaben inkl. 14-tägiger Vorreihung abgebildet.</li>
              </ul>

              <div class="row head-row">
                <div class="col-lg-6 col-6 text-align-left">Laufzeit</div>
                <div class="col-lg-6 col-6 text-align-right">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-6 text-align-left">60 Tage</div>
                <div class="col-lg-6 col-6 text-align-right">150,00</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/digital/willhaben-stellenanzeige.jpg" alt="Willhaben Stellenanzeige" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer.</div>
      </Tab>
    </TabGroup>
    <div class="row">
      <div class="col-lg-6">
        <div class="download-pdf">
          <div class="line"><span>DOWNLOAD</span></div>
          <a href="../../pdf/marktplaetze/Beruf-und-Bildung-07-2021.pdf" target="_blank">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Tarif Beruf &amp; Bildung
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>1.959 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="download-pdf">
          <div class="line"><span>DOWNLOAD</span></div>
          <a href="../../pdf/marktplaetze/Mojedelo_Tarif_2021_v2.pdf" target="_blank">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Tarif Mojedelo
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>481 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Bildung Beruf',
  components: {
    //Modal,
    TabGroup,
    Tab,
  },
});
</script>
