
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Bildung Beruf',
  components: {
    //Modal,
    TabGroup,
    Tab,
  },
});
